import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2016-02-02T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "/reading/ready-player-one-giant.jpg",
        "alt": "2312 concept art"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/reading/ready-player-one.jpg",
        "alt": "2312 concept art"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/reading/ready-player-one-dancing.jpg",
        "alt": "2312 concept art"
      }}></img></p>
    <ul>
      <li parentName="ul">{`Title: `}<strong parentName="li">{`Ready Player One`}</strong></li>
      <li parentName="ul">{`Ernest Cline`}</li>
    </ul>
    <p>{`My rating: ★★★★`}</p>
    <p>{`Really enjoyed this book because of the reminders of 80's music and movies, and the visuals it conjured up. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      